<template>

  <div :class="(darkMode ? 'dark' : 'light')" class="page">
    <div class="header px-3 justify-content-between">

      <div class="d-flex align-items-center">

        <div class="logo ">

          <img src="https://raw.githubusercontent.com/open-defi-notification-protocol/projects/master/twap/logo.png"/>

        </div>

        <div class="main-title mx-4 d-flex flex-column">
          <span>dTWAP Takers</span>
          <span class="powered-by">
          Powered by Orbs<img src="https://www.orbs.com/assets/img/common/logo.svg"
                              onclick="window.open('https://orbs.com')">
            </span>
        </div>

      </div>

      <div class=" title-buttons d-flex">

        <button @click="toggleTheme" class="mx-2 btn btn-primary">
          <font-awesome-icon v-if="!darkMode" icon="fa-solid fa-moon"/>
          <font-awesome-icon v-else icon="fa-solid fa-sun"/>
        </button>

        <button @click="loaderFunction(true)" class="mx-2 btn btn-primary">
          <font-awesome-icon icon="fa-solid fa-rotate"/>
        </button>

      </div>

    </div>

    <div style="display: flex; flex-direction: column;" class="px-3 main-container">

      <!-- L3 -->
      <div class="card" style="display: flex; flex-direction: column; margin-top: 9.5rem;">
        <div class="  pb-2 card-title">L3 Takers Status</div>
        <div class="table-responsive">
          <table class="  common-table table  table-hover " v-if="backupTakersStatus[0]">
            <thead class="">
            <tr>
              <th>
                Node
              </th>
              <th>
                Status
              </th>
              <th>
                Taker Address
              </th>
              <!-- no need for now
                            <th>
                              Instance Index
                            </th>
                            <th>
                              Load Balance Nonce
                            </th>
                            -->
              <th>
                Version
              </th>
              <th>
                Up time (h)
              </th>
              <th>
                Mem usage (mb)
              </th>
              <th>
                Warnings Count
              </th>
              <th>
                Bids
              </th>
              <th>
                Fills
              </th>
              <th>
                Fantom Balance
              </th>
              <th>
                Avalanche Balance
              </th>
              <th>
                Polygon Balance
              </th>
              <th>
                Arbitrum Balance
              </th>
              <th>
                BSC Balance
              </th>
              <th v-for="dexKey in Object.keys(backupTakersStatus[0].dexes)" :key="dexKey">
                {{ dexKey }} elps / onBlocks / blockAge / [bids -> fills]
              </th>
            </tr>
            </thead>

            <tbody>

            <tr v-for="takerStatus in l3TakersStatus" :key="takerStatus.instanceIndex">
              <td><a target="_blank" :href="takerStatus.url">{{ takerStatus.name }}</a></td>
              <td :title="takerStatus.Status">{{
                  takerStatus.Status.substring(9, takerStatus.Status.indexOf(','))
                }}
              </td>
              <td>{{ takerStatus.nodeAddress.slice(0, 6) + '...' + takerStatus.nodeAddress.slice(-4) }}</td>
              <!-- no need for now
                            <td>{{ takerStatus.instanceIndex }}</td>
                            <td>{{ takerStatus.loadBalanceNonce }}</td>
              -->
              <td>{{ takerStatus.Payload.Version.Semantic }}</td>
              <td>{{ (takerStatus.uptime / 60 / 60).toFixed(1) }}</td>
              <td>{{ Math.round(takerStatus.heapUsedMB) }}</td>
              <td>{{ takerStatus.errorCount }}</td>
              <td>{{ takerStatus.total.bids }}</td>
              <td>{{ takerStatus.total.fills }}</td>
              <td :title="getL3TakerBalance(takerStatus, 'fantom')">
                <a :href="'https://ftmscan.com/address/'+takerStatus.nodeAddress" class="text-decoration-none"
                   target="_blank"
                   :style="getL3TakerBalanceStyle(takerStatus, 'fantom', 50)">
                  {{ numberFormatter.format(getL3TakerBalance(takerStatus, 'fantom')) }}
                </a>
              </td>
              <td :title="getL3TakerBalance(takerStatus, 'avalanche')">
                <a :href="'https://snowtrace.io/address/'+takerStatus.nodeAddress" class="text-decoration-none"
                   target="_blank"
                   :style="getL3TakerBalanceStyle(takerStatus, 'avalanche', 0.1)">
                  {{ numberFormatter.format(getL3TakerBalance(takerStatus, 'avalanche')) }}
                </a>
              </td>
              <td :title="getL3TakerBalance(takerStatus, 'polygon')">
                <a :href="'https://polygonscan.com/address/'+takerStatus.nodeAddress" class="text-decoration-none"
                   target="_blank"
                   :style="getL3TakerBalanceStyle(takerStatus, 'polygon', 10)">
                  {{ numberFormatter.format(getL3TakerBalance(takerStatus, 'polygon')) }}
                </a>
              </td>
              <td :title="getL3TakerBalance(takerStatus, 'arbitrum')">
                <a :href="'https://arbiscan.io/address/'+takerStatus.nodeAddress" class="text-decoration-none"
                   target="_blank"
                   :style="getL3TakerBalanceStyle(takerStatus, 'arbitrum', 0.005)">
                  {{ numberFormatter.format(getL3TakerBalance(takerStatus, 'arbitrum')) }}
                </a>
              </td>
              <td :title="getL3TakerBalance(takerStatus, 'bsc')">
                <a :href="'https://bscscan.com/address/'+takerStatus.nodeAddress" class="text-decoration-none"
                   target="_blank"
                   :style="getL3TakerBalanceStyle(takerStatus, 'bsc', 0.01)">
                  {{ numberFormatter.format(getL3TakerBalance(takerStatus, 'bsc')) }}
                </a>
              </td>
              <td v-for="dexKey in Object.keys(takerStatus.dexes)" :key="dexKey">
                <span :style="getTakerLatencyStyle(takerStatus.dexes[dexKey].maxElapsedTime,20000, 'orange')">{{
                    takerStatus.dexes[dexKey].maxElapsedTime
                  }}</span> /
                <span
                    :style="getTakerLatencyStyle(Date.now() - takerStatus.dexes[dexKey].lastOnBlocksExecution, 1200000)">{{
                    Date.now() - takerStatus.dexes[dexKey].lastOnBlocksExecution
                  }}</span> /
                <span :style="getTakerLatencyStyle(takerStatus.dexes[dexKey].lastScannedBlockAge, 300000)">{{
                    takerStatus.dexes[dexKey].lastScannedBlockAge
                  }}</span> <br/>

                <br/> [ {{ takerStatus.dexes[dexKey].bids }} -> {{ takerStatus.dexes[dexKey].fills }} ]

              </td>
            </tr>
            <tr class="fw-bolder">
              <td colspan="10">Total</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.l3TakersStatus, 'fantom')) }}</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.l3TakersStatus, 'avalanche')) }}</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.l3TakersStatus, 'polygon')) }}</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.l3TakersStatus, 'arbitrum')) }}</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.l3TakersStatus, 'bsc')) }}</td>
            </tr>


            </tbody>

          </table>
        </div>
      </div>

      <!-- Backup -->
      <div class="card" style="display: flex; flex-direction: column;">
        <div class="  pb-2 card-title">Backup Takers Status</div>
        <div class="table-responsive">
          <table class="  common-table table  table-hover " v-if="backupTakersStatus[0]">
            <thead class="">
            <tr>
              <th>
                Taker ID
              </th>
              <th>
                Status
              </th>
              <!-- no need for now
                            <th>
                              Instance Index
                            </th>
                            <th>
                              Load Balance Nonce
                            </th>
                            -->
              <th>
                Version
              </th>
              <th>
                Up time (h)
              </th>
              <th>
                Mem usage (mb)
              </th>
              <th>
                Warnings Count
              </th>
              <th>
                Biddable Orders
              </th>
              <th>
                Fillable Orders
              </th>
              <th>
                Bids
              </th>
              <th>
                Fills
              </th>
              <th v-for="dexKey in Object.keys(getAllDexKeys(backupTakersStatus))" :key="dexKey">
                {{ dexKey }} elps / onBlocks / blockAge / [bids -> fills]
              </th>
            </tr>
            </thead>

            <tbody>

            <tr v-for="takerStatus in backupTakersStatus" :key="takerStatus.instanceIndex">
              <td><a target="_blank" :href="takerStatus.url">{{ takerStatus.nodeAddress }}</a></td>
              <td :title="takerStatus.Status">{{
                  takerStatus.Status.substring(9, takerStatus.Status.indexOf(','))
                }}
              </td>
              <!-- no need for now
                            <td>{{ takerStatus.instanceIndex }}</td>
                            <td>{{ takerStatus.loadBalanceNonce }}</td>
              -->
              <td>{{ takerStatus.Payload.Version.Semantic }}</td>
              <td>{{ (takerStatus.uptime / 60 / 60).toFixed(1) }}</td>
              <td>{{ Math.round(takerStatus.heapUsedMB) }}</td>
              <td>{{ takerStatus.errorCount }}</td>
              <td>{{ takerStatus.total.biddableOrders }}</td>
              <td>{{ takerStatus.total.fillableOrders }}</td>
              <td>{{ takerStatus.total.bids }}</td>
              <td>{{ takerStatus.total.fills }}</td>
              <td v-for="dexKey in Object.keys(getAllDexKeys(backupTakersStatus))" :key="dexKey">
                <span v-if="takerStatus.dexes[dexKey]" :style="getTakerLatencyStyle(takerStatus.dexes[dexKey].maxElapsedTime,40000, 'orange')">{{
                    takerStatus.dexes[dexKey].maxElapsedTime
                  }}</span> /
                <span v-if="takerStatus.dexes[dexKey]"
                    :style="getTakerLatencyStyle(Date.now() - takerStatus.dexes[dexKey].lastOnBlocksExecution, 120000)">{{
                    Date.now() - takerStatus.dexes[dexKey].lastOnBlocksExecution
                  }}</span> /
                <span v-if="takerStatus.dexes[dexKey]" :style="getTakerLatencyStyle(takerStatus.dexes[dexKey].lastScannedBlockAge, 60000)">{{
                    takerStatus.dexes[dexKey].lastScannedBlockAge
                  }}</span>
                <br/> <span v-if="takerStatus.dexes[dexKey]">[ {{ takerStatus.dexes[dexKey].bids }} -> {{ takerStatus.dexes[dexKey].fills }} ]</span>
              </td>
            </tr>

            </tbody>

          </table>

          <table class="  common-table table  table-hover " v-if="Object.values(backupTakersWallets).length > 0">
            <thead class="">
            <tr>
              <th>
                Wallet
              </th>
              <th>
                Fantom Balance
              </th>
              <th>
                Avalanche Balance
              </th>
              <th>
                Polygon Balance
              </th>
              <th>
                Arbitrum Balance
              </th>
              <th>
                BSC Balance
              </th>
            </tr>
            </thead>

            <tbody>

            <tr v-for="wallet of Object.keys(backupTakersWallets)" :key="wallet">

              <td>{{ wallet }}</td>

              <td :title="backupTakersWallets[wallet].fantom">
                <a :href="'https://ftmscan.com/address/'+wallet" class="text-decoration-none" target="_blank"
                   :style="getTakerBalanceStyle(backupTakersWallets[wallet].fantom, 50)">
                  {{ numberFormatter.format(backupTakersWallets[wallet].fantom) }}
                </a>
              </td>
              <td :title="backupTakersWallets[wallet].avalanche">
                <a :href="'https://snowtrace.io/address/'+wallet" class="text-decoration-none" target="_blank"
                   :style="getTakerBalanceStyle(backupTakersWallets[wallet].avalanche, 0.1)">
                  {{ numberFormatter.format(backupTakersWallets[wallet].avalanche) }}
                </a>
              </td>
              <td :title="backupTakersWallets[wallet].arbitrum">
                <a :href="'https://polygonscan.com/address/'+wallet" class="text-decoration-none" target="_blank"
                   :style="getTakerBalanceStyle(backupTakersWallets[wallet].polygon, 10)">
                  {{ numberFormatter.format(backupTakersWallets[wallet].polygon) }}
                </a>
              </td>
              <td :title="backupTakersWallets[wallet].arbitrum">
                <a :href="'https://arbiscan.io/address/'+wallet" class="text-decoration-none" target="_blank"
                   :style="getTakerBalanceStyle(backupTakersWallets[wallet].arbitrum, 0.005)">
                  {{ numberFormatter.format(backupTakersWallets[wallet].arbitrum) }}
                </a>
              </td>
              <td :title="backupTakersWallets[wallet].bsc">
                <a :href="'https://bscscan.com/address/'+wallet" class="text-decoration-none" target="_blank"
                   :style="getTakerBalanceStyle(backupTakersWallets[wallet].bsc, 0.01)">
                  {{ numberFormatter.format(backupTakersWallets[wallet].bsc) }}
                </a>
              </td>

            </tr>
            <tr v-for="takerStatus in backupTakersStatus" :key="takerStatus.instanceIndex">
              <td>{{ takerStatus.nodeAddress }}</td>
              <td>{{ numberFormatter.format(getTotalBackupTakerBalance(takerStatus, 'fantom')) }}</td>
              <td>{{ numberFormatter.format(getTotalBackupTakerBalance(takerStatus, 'avalanche')) }}</td>
              <td>{{ numberFormatter.format(getTotalBackupTakerBalance(takerStatus, 'polygon')) }}</td>
              <td>{{ numberFormatter.format(getTotalBackupTakerBalance(takerStatus, 'arbitrum')) }}</td>
              <td>{{ numberFormatter.format(getTotalBackupTakerBalance(takerStatus, 'bsc')) }}</td>
            </tr>
            <tr class="fw-bolder">
              <td>Total</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.backupTakersStatus, 'fantom')) }}</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.backupTakersStatus, 'avalanche')) }}</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.backupTakersStatus, 'polygon')) }}</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.backupTakersStatus, 'arbitrum')) }}</td>
              <td>{{ numberFormatter.format(getAllTakersBalance(this.backupTakersStatus, 'bsc')) }}</td>
            </tr>

            </tbody>

          </table>
        </div>
      </div>

      <div class="card" style="display: flex; flex-direction: column;" v-if="this.wmStatus">
        <div class="  pb-2 card-title"><a :href="wmStatus.healthUrl" target="_blank">Wallet Manager</a> | <a
            :href="wmStatus.adminUrl" target="_blank">Admin</a></div>
        <div class="table-responsive">

          <table class="  common-table table  table-hover ">
            <thead class="">
            <tr>
              <th>
                Status
              </th>
              <th>
                Version
              </th>
              <th>
                Uptime (h)
              </th>
              <th>
                Mem usage (mb)
              </th>
              <th>
                Errors Count
              </th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td>{{ wmStatus.status }}</td>
              <td>{{ wmStatus.version }}</td>
              <td>{{ (wmStatus.uptime / 60 / 60).toFixed(1) }}</td>
              <td>{{ Math.round(wmStatus.memory.heapUsedMB) }}</td>
              <td>{{ wmStatus.errorCount }}</td>
            </tr>
            </tbody>

          </table>

          <br/>
          <br/>

          <table class="  common-table table  table-hover ">
            <thead class="">
            <tr>
              <th>
                Network
              </th>
              <th>
                Treasury Balance
              </th>
              <th>
                Pool Balance
              </th>
              <th>
                Pool Size
              </th>
              <th>
                Available Wallets
              </th>
              <th>
                Pending Wallets
              </th>
              <th>
                Unusable Wallets
              </th>
              <th>
                Errors Count
              </th>
            </tr>
            </thead>

            <tbody>

            <tr v-for="network of Object.keys(wmStatus.networks)" :key="network">
              <td>{{ network }}</td>
              <td>{{ numberFormatter.format(wmStatus.networks[network].wallets.treasury.balanceInWU) }}
                (<span :style="getTreasuryBalanceDiff(wmStatus.networks[network]) >=0 ? 'color:green;' : 'color:red;'">{{
                    getTreasuryBalanceDiff(wmStatus.networks[network]) > 0 ? '+' : ''
                  }}{{
                    numberFormatter.format(getTreasuryBalanceDiff(wmStatus.networks[network]))
                  }}</span>)
              </td>
              <td>{{ numberFormatter.format(getTotalWmBalance(wmStatus.networks[network].wallets.all)) }}
                (<span :style="getPoolBalanceDiff(wmStatus.networks[network]) >=0 ? 'color:green;' : 'color:red;'">{{
                    getPoolBalanceDiff(wmStatus.networks[network]) > 0 ? '+' : ''
                  }}{{
                    numberFormatter.format(getPoolBalanceDiff(wmStatus.networks[network]))
                  }}</span>)
              </td>
              <td>{{ Object.keys(wmStatus.networks[network].wallets.all).length }}</td>
              <td>{{ Object.keys(wmStatus.networks[network].wallets.availableWallets).length }}</td>
              <td>{{ Object.keys(wmStatus.networks[network].wallets.pendingWallets).length }}</td>
              <td>{{ Object.keys(wmStatus.networks[network].wallets.unusableWallets).length }}</td>
              <td>{{ wmStatus.networks[network].errorCount }}</td>
            </tr>

            </tbody>

          </table>

          <br/>
          <br/>

          <table class="  common-table table  table-hover ">
            <thead class="">
            <tr>
              <th>
                Network
              </th>
              <th>
                Call Success
              </th>
              <th>
                Call Failed
              </th>
              <th>
                Send Success
              </th>
              <th>
                Send Failed
              </th>
              <th>
                TX Success
              </th>
              <th>
                TX Revert
              </th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="network of Object.keys(wmStatus.networks)" :key="network">
              <td>{{ network }}</td>
              <td>{{ wmStatus.networks[network].transactions.calls }}</td>
              <td>{{ wmStatus.networks[network].transactions.callsFailed }}</td>
              <td>{{ wmStatus.networks[network].transactions.send }}</td>
              <td>{{ wmStatus.networks[network].transactions.failed }}</td>
              <td>{{ wmStatus.networks[network].transactions.success }}</td>
              <td>{{ wmStatus.networks[network].transactions.revert }}</td>
            </tr>

            </tbody>

          </table>

          <br/>
          <br/>

          <table class="  common-table table  table-hover ">
            <thead class="">
            <tr>
              <th>
                Network
              </th>
              <th>
                Initial
              </th>
              <th>
                Refill threshold
              </th>
              <th>
                Purge threshold
              </th>
              <th>
                Treasury minimum
              </th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="network of Object.keys(wmStatus.networks)" :key="network">
              <td>{{ network }}</td>
              <td>{{ wmStatus.metadata.networks[network].walletThresholds.initial }}</td>
              <td>{{ wmStatus.metadata.networks[network].walletThresholds.refill }}</td>
              <td>{{ wmStatus.metadata.networks[network].walletThresholds.purge }}</td>
              <td>{{ wmStatus.metadata.networks[network].walletThresholds.minTreasury }}</td>
            </tr>

            </tbody>

          </table>
        </div>
      </div>

      <br/>

      <div>
        <canvas id="balancesChart"></canvas>
      </div>

    </div>

    <div class="loading" v-if="isLoading">
      <img
          src="https://media3.giphy.com/media/L05HgB2h6qICDs5Sms/giphy.gif?cid=ecf05e478m15g7rgia18ipg9menvhnn4ahik4vcjd5zzr33r&rid=giphy.gif&ct=s"/>
    </div>

  </div>

</template>

<script>

import Vue from 'vue'
import './scss/style.scss'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
  faCheck,
  faDatabase,
  faMoon,
  faNetworkWired,
  faRemove,
  faRotate,
  faServer,
  faSun,
  faWarning
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faCheck)
library.add(faRemove)
library.add(faWarning)
library.add(faRotate)
library.add(faMoon)
library.add(faSun)
library.add(faServer)
library.add(faDatabase)
library.add(faNetworkWired)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

const numberFormatter = Intl.NumberFormat('en', {notation: 'compact'});

export default {
  name: 'App',

  components: {},

  data() {
    return {
      addresses: ['0x154C7D38B10d8Ec1Bf9b4e8E671b557dA6F9E52B', '0x3dacC571356e7D5dFB3b475d6922442Ec06B9005'],
      API_KEY: 'MWCMQ8NDIK2W1IHV6Q1QNJCJTX3SCAT4V9',
      balances: [],
      publicPath: process.env.BASE_URL,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'left'
        }
      },
      optionsNoLegend: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false
      },
      barOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          yAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }]
        }
      },
      networksPerformanceChartDataSet: null,
      networksChartDataSet: null,
      networksColors: {
        'ethereum': '#5971e3',
        'bsc': '#f8c62c',
        'polygon': '#743ad6',
        'avalanche': '#e53d3f',
        'fantom': '#13aae6',
        'cronos': '#092b49',
        'http': '#6ed54d'
      },
      projectsChartDataSet: null,
      auditChartDataSet: null,
      l3TakersStatus: [],
      backupTakersStatus: [],
      wmStatus: null,
      darkMode: false,
      isLoading: true
    }
  },

  async mounted() {

    this.darkMode = this.getUrlParams('dark') === 'true';

    document.documentElement.dataset.scroll = window.scrollY;

    document.addEventListener('scroll', () => {
      document.documentElement.dataset.scroll = window.scrollY;
    });

    await this.loaderFunction()

    if (this.getUrlParams('cycle') === 'true') {
      setInterval(() => {
        this.loaderFunction(true, true)
      }, 30000)
    }

    window.addEventListener('beforeunload', this.saveScrollPosition)

  },
  beforeDestroy() {

    window.removeEventListener('beforeunload', this.saveScrollPosition)

  },
  computed: {

    numberFormatter() {
      return numberFormatter
    },

    backupTakersWallets() {

      const _backupTakersWallets = {}

      for (const backupTaker of Object.values(this.backupTakersStatus)) {

        for (const network in backupTaker.takersWallets) {

          const walletsBalances = backupTaker.takersWallets[network]

          for (const wallet in walletsBalances) {

            _backupTakersWallets[wallet] = _backupTakersWallets[wallet] || {}

            _backupTakersWallets[wallet][network] = walletsBalances[wallet].balance

          }

        }

      }

      return _backupTakersWallets

    }


  },
  methods: {

    saveScrollPosition() {

      localStorage.setItem('scrollPositionX', window.scrollX.toString())
      localStorage.setItem('scrollPositionY', window.scrollY.toString())

    },
    restoreScrollPosition() {

      if (localStorage.getItem('scrollPositionX') !== null && localStorage.getItem('scrollPositionY') !== null) {

        window.scrollTo({
          top: parseInt(localStorage.getItem('scrollPositionY')),
          left: parseInt(localStorage.getItem('scrollPositionX')),
          behavior: 'instant'
        })

      }

    },
    fromWei(weiValue) {
      return weiValue / (10 ** 18);
    },
    getUrlParams(name) {
      const query = new URLSearchParams(window.location.search)
      return query.get(name)
    },
    getL3TakerBalance(takerStatus, network) {

      let takersWallet = takerStatus.takersWallets[network];
      return takersWallet ? Object.values(takersWallet)[0].balance : 0

    },
    getAllTakersBalance(takersStatus, network) {

      let total = 0

      for (const takerStatus of takersStatus) {

        if (takerStatus.takersWallets) {

          const takersWallets = takerStatus.takersWallets[network]

          for (const takerWalletAddress in takersWallets) {
            total += parseFloat(takersWallets[takerWalletAddress].balance)
          }

        }

      }

      return total

    },
    getBackupTakerBalance(takerStatus, network, walletIndex) {

      let takersWallet = takerStatus.takersWallets && takerStatus.takersWallets[network];
      return takersWallet ? Object.values(takersWallet)[walletIndex].balance : 0

    },
    getTotalBackupTakerBalance(takerStatus, network) {

      let total = 0

      if (takerStatus.takersWallets) {

        const takersWallets = takerStatus.takersWallets[network]

        for (const takerWalletAddress in takersWallets) {
          total += parseFloat(takersWallets[takerWalletAddress].balance)
        }

      }

      return total

    },

    getTakerBalanceStyle(balance, threshold) {
      return 'color: ' + ((balance && balance > threshold) ? 'green;' : ((!balance || balance <= 0) ? 'red !important;' : 'orange;'))
    },

    getTakerLatencyStyle(latency, threshold, color) {
      return 'color: ' + ((latency && latency > threshold) ? ((color || 'red') + ' !important;') : 'black;')
    },

    getL3TakerBalanceStyle(takerStatus, network, threshold) {

      const balance = this.getL3TakerBalance(takerStatus, network)

      return this.getTakerBalanceStyle(balance, threshold)

    },
    getAllDexKeys() {

      const allKeys = {}

      for (const backupTaker of Object.values(this.backupTakersStatus)) {

        for (const network of Object.values(backupTaker.networks)) {

          for (const dexKey in network.dexes) {
            allKeys[dexKey] = dexKey

          }
        }
      }

      return allKeys

    },
    async loaderFunction(isReload, noLoadingCurtain) {

      if (isReload) {
        this.saveScrollPosition()
      }

      if (!noLoadingCurtain) {

        this.isLoading = true

      }

      this.$nextTick(async () => {

          try {

            const l3statusUrl = 'https://status.orbs.network/json-full'
            // let l3statusUrl = 'http://localhost:8081/json'

            const l3status = await (await fetch(l3statusUrl)).json()

            for (const committeeNode of Object.values(l3status.CommitteeNodes)) {

              const twapL3Status = committeeNode.NodeServices['vm-twap']

              if (twapL3Status) {

                const takerStatus = twapL3Status.VMStatusJson

                if (twapL3Status.VMStatusJson && (takerStatus.instanceIndex === 0 || takerStatus.instanceIndex)) {

                  takerStatus.url = twapL3Status.URLs.Status
                  takerStatus.name = committeeNode.Name
                  takerStatus.dexes = {}

                  for (const network of Object.values(takerStatus.networks)) {

                    takerStatus.dexes = Object.assign(takerStatus.dexes, network.dexes)

                  }

                  this.mergeStatus(this.l3TakersStatus, takerStatus)

                }

              }

            }

            this.l3TakersStatus.sort((a, b) => a.instanceIndex - b.instanceIndex)

          } catch (ignore) {
            console.warn(ignore)
          }

        const backupTakers = [
          'https://twap-taker-1.herokuapp.com/health',
          'https://twap-taker-2.herokuapp.com/health',
          'https://twap-taker-twap3-4463356499dc.herokuapp.com/health'
        ]

        await Promise.all(
            Object.values(backupTakers).map(backupTakerUrl => (async () => {

              try {

                const takerStatus = await (await fetch(backupTakerUrl)).json()

                takerStatus.url = backupTakerUrl
                takerStatus.name = takerStatus.nodeAddress
                takerStatus.dexes = {}

                for (const network of Object.values(takerStatus.networks)) {

                  takerStatus.dexes = Object.assign(takerStatus.dexes, network.dexes)

                }

                this.mergeStatus(this.backupTakersStatus, takerStatus)

                this.backupTakersStatus.sort((a, b) => a.nodeAddress.localeCompare(b.nodeAddress))

              } catch (ignore) {
                console.warn(ignore)
              }

            })())
        )

        const wmUrl = "https://wallet-manager-1-a1922d7bed1d.herokuapp.com"

        const healthUrl = wmUrl + '/health'

        const wmStatus = await (await fetch(healthUrl)).json()

        wmStatus.healthUrl = healthUrl
        wmStatus.adminUrl = wmUrl + '/admin'

        if (this.wmStatus) {
          this.deepMerge(this.wmStatus, wmStatus)
        } else {
          this.wmStatus = wmStatus
        }

        this.$nextTick(() => {
          setTimeout(this.restoreScrollPosition, 10)
        })

        this.isLoading = false

      })

    },

    toggleTheme() {
      this.darkMode = !this.darkMode
    },

    getTotalWmBalance(walletsJson) {

      let total = 0

      for (const wallets of Object.values(walletsJson)) {

        total += parseFloat(wallets.balanceInWU)

      }

      return total

    },

    getTreasuryBalanceDiff(networkStatus) {

      const diff = networkStatus.wallets.treasury.balanceInWU - networkStatus.wallets.initialState.treasuryBalance

      const changeRatio = Math.abs(diff / networkStatus.wallets.initialState.treasuryBalance)

      return changeRatio > 0.0009 ? diff : 0

    },
    getPoolBalanceDiff(networkStatus) {

      const diff = this.getTotalWmBalance(networkStatus.wallets.all) - networkStatus.wallets.initialState.poolBalance

      const changeRatio = Math.abs(diff / networkStatus.wallets.initialState.poolBalance)

      return changeRatio > 0.0009 ? diff : 0

    },

    mergeStatus(takersStatusArray, newStatus) {

      const takerStatus = takersStatusArray.find(takerStatus => takerStatus.nodeAddress === newStatus.nodeAddress)

      if (takerStatus) {

        this.deepMerge(takerStatus, newStatus)

      } else {

        takersStatusArray.push(newStatus)

      }

    },

    deepMerge(target, source) {
      const isObject = (obj) => obj && typeof obj === 'object';

      if (!isObject(target) || !isObject(source)) {
        return source;
      }

      Object.keys(source).forEach(key => {
        const targetValue = target[key];
        const sourceValue = source[key];

        if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
          target[key] = targetValue.concat(sourceValue);
        } else if (isObject(targetValue) && isObject(sourceValue)) {
          target[key] = this.deepMerge(Object.assign({}, targetValue), sourceValue);
        } else {
          target[key] = sourceValue;
        }
      });

      return target;
    }

  }

}
</script>
